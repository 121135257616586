import axios from "axios"
import {getTokenFromLocalstorage} from '../auth'


export default {
    install(app) {
        app.config.globalProperties.$baseURL = "https://web2.hamroh.com/api_hamroh_gram/public/api/";
        // app.config.globalProperties.$baseURL = "https://test-hamroh.gram.tj/test/api/"

        const api = axios.create({
            baseURL: app.config.globalProperties.$baseURL
        })

        const token = localStorage.getItem('token')
        
        if(token) {
            api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }

        app.config.globalProperties.$http = api


        api.interceptors.response.use(
            (response) => {
                const accessToken = response.data?.result?.access_token;
                if (accessToken) {
                    localStorage.setItem('token', accessToken);
                    api.defaults.headers.common['Authorization'] = `Bearer ${getTokenFromLocalstorage()}`;
                }
                return response
            },
            (error) => {
                const { response } = error;
                if (response && response.status === 401) {
                    console.log("Unauthorized - 401");
                }
                return Promise.reject(error);
            }
        )
    }
}