export default {
    namespaced: true,
    state: {
        isConnection: false,
        ua: null,
        session: null,
        isMuted: false,
        confirmed: null,
        isLoading: false,
        number: null
    },
    getters: {

    },
    mutations: {
        connection(state, value) {
            state.isConnection = value
        },
        uaSetting(state, value) {
            state.ua = value
        },
        sessionSetting(state, value) {
            state.session = value
        },
        muted(state, value) {
            state.isMuted = value
        },
        confirmedFun(state, value) {
            state.confirmed = value
        },
        Loading(state, value) {
            state.isLoading = value
        },
        numberFun(state, value) {
            state.number = value
        }
    },
    actions: {

    }
}