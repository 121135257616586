import { createStore } from 'vuex'

import navbar from './navbar'
import sip from './sip'
import modal from './modal'

const store = createStore({
  modules: {
    navbar,
    sip,
    modal
  }
})

export default store