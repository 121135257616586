export default {
    namespaced: true,
    state: {
        isOpen: false
    },
    getters: {
    },
    mutations: {
        openPhone(state, value) {
            state.isOpen = value
        },
        closePhone(state, value) {
            state.isOpen = value
        },
    },
    actions: {

    }
}