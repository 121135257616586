import VueTheMask from 'vue-the-mask'
import apiPlugin  from '@/api/axios'
import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import './style.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueTheMask)
app.mount('#app')
app.use(apiPlugin)