<template>
    <div class="flex ml-2">
        <div>
            <label class="inline-flex items-center cursor-pointer">
                <input v-model="store.state.sip.isConnection" @change="sipConnect($event)" type="checkbox" class="sr-only peer">
                <div class="relative w-12 h-6 bg-blue-200 rounded-full 
                            peer peer-checked:after:translate-x-full 
                            rtl:peer-checked:after:-translate-x-full 
                            peer-checked:after:border-white after:content-[''] 
                            after:absolute after:top-[2px] after:start-[2px] 
                            after:bg-white after:border-gray-300 after:border 
                            after:rounded-full after:h-5 
                            after:w-5 after:transition-all  
                            peer-checked:bg-black">
                </div>
                <span v-if="!store.state.closeMenu" class="ms-3 text-sm font-medium mr-5">SIP</span>
            </label>
        </div>
        <div v-show="store.state.sip.isLoading && !store.state.closeMenu" class="border-gray-200 h-7 w-7 animate-spin rounded-full border-4 border-t-black flex items-center"></div>
    </div>
    <button style="margin-right: 30px;" class="ml-2" @click="openPhone()" :disabled="!acceptCall"><img src="@/assets/svg/phone.svg" alt=""></button>
    <modal @closeModal="closePhone()"  ref="phoneModal" :title="'SIP'">
        <div>
            <div class="flex items-center mt-5 mx-5">
                <input 
                placeholder="Номер телефона" 
                type="text"
                @keyup.enter="call()" 
                v-model.trim="store.state.sip.number" 
                class="phone-number w-full"
                >
                <span class="timer" v-if="store.state.sip.session && store.state.sip.confirmed">
                    {{time}}
                </span>
            </div>
            <hr>
            <div class="flex justify-center mt-3" v-if="store.state.sip.session">
                <button @click="muteOrUmmute()" class="flex py-[8px] px-[80px] text-center text-[14px] rounded-[10px] session-action">
                    Микрофон
                    <microphone v-if="!store.state.sip.isMuted" class="mt-1 ml-5"/>
                    <microphoneOff v-else class="mt-1 ml-5"/>
                </button>
            </div>
            <div class="mt-3 mx-3" v-if="!store.state.sip.session">
                <button  @click="call()" class="control-btn rounded-[10px] w-full mt-[10px]">Вызов</button>
            </div>
            <div class="mt-3 mx-3" v-else-if="store.state.sip.session.direction === 'incoming' && store.state.sip.confirmed !== false" >
                <button class="control-btn w-full rounded-[10px]" @click="acceptCallCliens()">Принять</button>
                <button class="control-btn hangup w-full rounded-[10px]" @click.prevent="hangup()">Отклонить</button>
            </div>
            <div class="mt-3 mx-3"  @click.prevent="hangup()" v-else>
                <button class="control-btn hangup w-full rounded-[10px]" >Завершить</button>
            </div>
        </div>
        <audio ref="audioPlayer" :audio="audio"></audio>
    </modal>
</template>

<script setup>
import audio from '@/components/sip/audio.mp3'
import { ref, computed, onMounted, createApp, toRaw } from 'vue';
import microphone from '@/components/icon/microphone.vue'
import microphoneOff from '@/components/icon/microphoneOff.vue'
import modal from '@/components/modal.vue'
import JsSIP from 'jssip'
import ringtone from '@/assets/ringtone.wav'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { useStore } from 'vuex'

const store = useStore()

const app = createApp({});
app.component('modal', modal)
app.component('microphone', microphone)
app.component('microphoneOff', microphoneOff)
const toast = useToast()
let socket = null
let remoteAudio = null
let ringtoneAudio = null
const phoneModal = ref(null)
const audioPlayer = ref(null)
const seconds = ref(0)
const timer = ref(null)
const configuration = {
mediaConstraints: {
    audio: true,
    video: false
},
rtcOfferConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: false
    }
}
onMounted(() =>{
  window.addEventListener('keydown', handleKeyDown);
})

const handleKeyDown = (event) => {
  const isCtrlPressed = event.ctrlKey || event.metaKey;

  const isMinusPressed = event.keyCode === 109;
  if(isCtrlPressed && isMinusPressed) {
      event.preventDefault();
      hangup()
  }
}

  const connectionSip = () => {
      store.commit('sip/Loading', true)
      if(filterCheck(JSON.parse(localStorage.getItem('sip')))){
          
          let dataSip = JSON.parse(localStorage.getItem('sip'))
          
          socket = new JsSIP.WebSocketInterface(`wss://${dataSip.serverAddress}${dataSip.serverPort ? ':' + dataSip.serverPort : ''}/ws`)
          remoteAudio = new window.Audio()
          ringtoneAudio = new Audio(ringtone)
          ringtoneAudio.autoplay = false
          ringtoneAudio.loop = 'loop'
          remoteAudio.autoplay = true

          try {
               const ua = new JsSIP.UA({
                  sockets: [socket],
                  display_name: dataSip.userNumber,
                  uri: `${dataSip.userNumber}@${dataSip.serverAddress}`,
                  password: `${dataSip.password}`,
                  session_timers: false
              })          
              store.commit('sip/uaSetting', ua)
          }
          catch(err){
              console.error('Error creating JsSIP.UA:', err);
          }
          store.state.sip.ua.on('connected', () => {
              toast.success(`SIP подключается`, {
                  position: 'top-right'
              });
          })

          store.state.sip.ua.on('registered', () => {
              store.commit('sip/Loading', false)
              toast.success(`SIP зарегистрирован`, {
                  position: 'top-right'
              });
          })

          store.state.sip.ua.on('disconnected', () => {
              store.commit('sip/connection', false)
              store.commit('sip/Loading', false)
              toast.error(`SIP отключён`, {
                  position: 'top-right'
              });
          })
          store.state.sip.ua.on('unregistered', () => {
              store.commit('sip/connection', false)
              store.commit('sip/Loading', false)
              toast.error(`SIP отключён`, {
                  position: 'top-right'
              });
          })
          store.state.sip.ua.on('registrationFailed', () => {
              store.commit('sip/connection', false)
              store.commit('sip/Loading', false)
              toast.error(`Неправильные данные SIP`, {
                  position: 'top-right'
              });
          })
          store.state.sip.ua.on('newRTCSession', (data) => {
              store.state.sip.session = data.session
              
              if(data.direction === 'incoming') {
                  openPhone()
                  store.commit('sip/numberFun', data.request.from._uri._user)
                  ringtoneAudio.play()
                  store.state.sip.session.on('accepted', (e) => {})
                  store.state.sip.session.on('confirmed', (e) => {
                      store.commit('sip/confirmedFun', true)
                      // ringtoneAudioPause() нужно посмотреть
                      timer.value = setInterval(()=>{
                          seconds.value++
                      }, 1000)
                  })
                  data.on('ended', (e) => {
                      store.commit('sip/sessionSetting', null)
                      ringtoneAudioPause()
                      store.commit('sip/confirmedFun', false)
                      store.commit('sip/numberFun', '')
                      clearInterval(timer.value)
                      seconds.value = 0
                  })
                  data.on('failed', (e) => {
                      ringtoneAudioPause()
                      store.commit('sip/sessionSetting', null)
                      store.commit('sip/confirmedFun', false)
                      store.commit('sip/numberFun', '')
                      seconds.value = 0
                      clearInterval(timer.value)
                  })
              }else {
                  store.commit('sip/sessionSetting', data.session)
                  openPhone()
              }
          })
          store.state.sip.ua.start()
      }else {
          store.commit('sip/connection', false)
          store.commit('sip/Loading', false)
          toast.error(`Заполните данные SIP!`, {
              position: 'top-right'
          });
      }
  }

  const ringtoneAudioPause = () => {
      if(ringtoneAudio !== undefined || ringtoneAudio !== null) {
          try {
              ringtoneAudio.pause();
              ringtoneAudio.srcObject = null;
          }catch {
              console.log('Ошибка ringtoneAudio');
          }
      }
  }

  const openPhone = () => {
    if(phoneModal.value) {
        phoneModal.value.isOpen = true
    }
  }

  const closePhone = () => {
    if(phoneModal.value) {
        phoneModal.value.isOpen = false
    }
  }

  const call = () => {
    store.commit('sip/numberFun', store.state.sip.number.replace(/ /g, '').toLowerCase())

      if(store.state.sip.session) return
      
      if(!store.state.sip.number) return

      const eventHandlers = {
          'progress': () => {
              store.state.sip.session.connection.ontrack = (e) => {
                  remoteAudio.srcObject = e.streams[0]
              };
          },
          'failed': () => {
              store.commit('sip/numberFun', null)
              store.commit('sip/sessionSetting', null)
              store.commit('sip/confirmedFun', false)
              clearInterval(timer.value)
              seconds.value = 0   
          },
          'ended': () => {
              store.commit('sip/numberFun', null)
              store.commit('sip/sessionSetting', null)
              store.commit('sip/confirmedFun', false)
              clearInterval(timer.value)
              seconds.value = 0
          },
          'confirmed': () => {
              store.commit('sip/confirmedFun', true)
              timer.value = setInterval(()=>{
                  seconds.value += 1
              }, 1000)
              playAudio()
          }
      }
      const options = {
          'eventHandlers': eventHandlers,
          'mediaConstraints': {'audio': true, 'video': false}
      }
      let rawSession = toRaw(store.state.sip.session)
      let rawUa = toRaw(store.state.sip.ua)
      rawSession = rawUa.call(store.state.sip.number, options)
  }

  const playAudio = () => {
        if(audioPlayer.value) {
            audioPlayer.value.src = audio;
            audioPlayer.value.play().catch(err => {
                console.error("Ошибка при воспроизведении аудио:", err);
            });
        }
  }

  const muteOrUmmute = () => {
      if(store.state.sip.session && !store.state.sip.session.isMuted().audio) {
          store.state.sip.session.mute({audio: true})
          store.commit('sip/muted', true)
      }else if(store.state.sip.session && store.state.sip.session.isMuted().audio){
          store.state.sip.session.unmute({audio: true})
          store.commit('sip/muted', false)
      }
      
  }

  const acceptCallCliens = () => {
      playAudio()
      store.state.sip.session.answer(configuration)
      store.state.sip.session.connection.ontrack = (e) => {
          remoteAudio.srcObject = e.streams[0]
          remoteAudio.play()
      }
  }
  
  const hangup = () => {
      if(store.state.sip.session) {
            const rawSession = toRaw(store.state.sip.session)
            rawSession.terminate()
      }
  }

  const sipConnect = (event) => {
    const isChecked = event.target.checked;
    isChecked ? connectionSip() : isDisconnection()
  }

  const acceptCall = computed(() => {
      return store.state.sip.isConnection ? true : false
  })

  const time = computed(() => {
      return parseInt(seconds.value / 60) + ':' + ((seconds.value % 60) < 10 ? '0' + (seconds.value % 60) : seconds.value % 60)
  })

  const isDisconnection = () => {
      let rawUa = toRaw(store.state.sip.ua)
      closePhone()
      store.commit('sip/Loading', false)
      rawUa.stop()
      toast.error(`SIP отключен`, {
          position: 'top-right'
      });
  }

  const filterCheck = (data) => {
      if(data) {
          if(data.password !== "" && data.serverAddress !== "" && data.serverPort !== ""  && data.userNumber !== "") {
              return true
          }else {
              return false
          }
      }else {
          return false
      }
  }

</script>

<style scoped>
.phone-number{
    padding: 8px;
    border: 1px solid #ffffff;
    border-left: none;
    border-right: none;
}
.phone-number:focus{
    outline: none;
}
.timer{
    top: 20px;
    right: 25px;
}
.session-action{
  outline: none;
  border: none;
  background-color: rgb(215, 215, 215);
  border-radius: 100px;
}
.session-action:active{
  background-color: rgb(200, 200, 200);
}
.control-btn{
    padding: 10px;
    background-color: #5BB85F;
    outline: none;
    border: none;
    color: #fff;
    margin-bottom: 10px;
}

.control-btn:active{
  background-color: #43A047;
}
.control-btn.hangup{
  background-color: #b32121;
}
.control-btn.hangup:active{
  background-color: #b32121a9;
}
</style>