<template>
  <div v-if="$store.state.navbar.isLoggedIn" ref="resizer" id="resizer" class="z-10 fixed bottom-0 bg-white border border-t-slate-400 min-h-24" :class="`${$store.state.closeMenu ? 'w-[70px]' : 'w-[260px]'}`">
    <div class="flex justify-center mb-[10px]">
      <div ref="buttonResizer" class="resizer-y"></div>
    </div>
    <sip/>
  </div>
  <router-view/>
</template>

<script>
import sip from '@/components/sip/sip.vue'
export default {
  components: {
    sip
  },  
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: 'login' });
    }
    this.dragResizer()
  },
  methods: {
    dragResizer() {
      const resizer = this.$refs.resizer;
      const buttonResizer = this.$refs.buttonResizer;

      if(resizer) {
        buttonResizer.onmousedown = (event) => {
          event.preventDefault();
          let shiftY = event.clientY - resizer.getBoundingClientRect().top;
  
          function moveAt(clientY) {
            resizer.style.top = clientY - shiftY + 'px';
          }
  
          moveAt(event.clientY);
  
          function onMouseMove(event) {
            moveAt(event.clientY);
          }
  
          document.addEventListener('mousemove', onMouseMove);
  
          document.onmouseup = function () {
            document.removeEventListener('mousemove', onMouseMove);
            document.onmouseup = null;
          };
  
          resizer.ondragstart = function () {
            return false;
          };
        };
      }
    }
  },
  watch: {
    '$store.state.navbar.isLoggedIn'(newValue) {
      if(newValue) {
        setTimeout(() => this.dragResizer(), 0)
      }
    }
  },
  computed: {
    isLoggedIn() {
        return Boolean(localStorage.getItem('token'));
      },
    },  

  }
</script>

<style scoped>
.resizer-y {
  @apply m-[2px] rounded-[10px] bg-[#8f8d8d] w-10 h-1 cursor-row-resize; 
}
</style>
